import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import {Button} from 'antd';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';

const QuestionTypeLabel = {
  single: '單選',
  multiple: '多選',
  bool: '是非',
  essay: '申論',
};

function getInitialSurveyValues(survey) {
  return survey.questions.map((q) => {
    if (q.type === 'essay') {
      return '';
    } else if (q.type === 'bool') {
      return false;
    } else {
      return {};
    }
  });
}

function getOptionSelectedCnt(resps, qIdx, cIdx) {
  return resps.reduce((acc, r) => {
    try {
      if (typeof cIdx === 'number') {
        if (r.values[qIdx][cIdx]) {
          return acc + 1;
        }
      } else {
        if (r.values[qIdx]) {
          return acc + 1;
        }
      }
    } catch (ex) {}
    return acc;
  }, 0);
}

function SurveyDetailPage(props) {
  const {id, token: _token} = qs.parse(props.location.search);

  const [survey, setSurvey] = React.useState(null);
  const [values, setValues] = React.useState({});
  const [resps, setResps] = React.useState([]);
  const [prevResp, setPrevResp] = React.useState(null);
  const [user] = useOutlet('user');

  const showResult = props.pageContext.showResult;
  const token = _token || (user && !user.tmp && user.token);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const _survey = await JStorage.fetchOneDocument(
          'survey',
          {
            id,
          },
          null,
          {anonymous: true},
        );
        setSurvey(_survey);
        setValues(getInitialSurveyValues(_survey));
      } catch (ex) {
        console.warn('SurveyDetailPage ex', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [id, showResult]);

  React.useEffect(() => {
    async function fetchAllResp() {
      try {
        setResps(
          await JStorage.fetchAllDocuments(
            'survey_resp',
            {
              survey: id,
            },
            null,
            null,
            token ? {token} : {anonymous: true},
          ),
        );
      } catch (ex) {
        console.warn('SurveyDetailPage ex', ex);
      }
    }

    if (showResult) {
      fetchAllResp();
    }
  }, [id, showResult, token]);

  React.useEffect(() => {
    async function checkUserResp() {
      try {
        setPrevResp(
          await JStorage.fetchOneDocument('survey_resp', {
            survey: id,
            owner: user.sub,
          }),
        );
      } catch (ex) {
        // bypass
      }
    }

    if (token) {
      checkUserResp();
    }
  }, [id, token, user]);

  function setOptionChecked(qIdx, cIdx, checked, qType) {
    if (qType === 'essay') {
      return;
    }

    const answer =
      qType === 'single' || qType === 'bool' ? {} : {...values[qIdx]};
    if (checked) {
      answer[cIdx] = true;
    } else {
      delete answer[cIdx];
    }

    const nextValues = [...values];
    nextValues[qIdx] = answer;
    setValues(nextValues);
  }

  function isOptionChosen(qIdx, cIdx) {
    try {
      return !!values[qIdx][cIdx];
    } catch (ex) {
      return false;
    }
  }

  async function submitMySurvey() {
    console.log('values', values);
    if (window.confirm('確認要送出嗎?')) {
      AppActions.setLoading(true);
      try {
        await JStorage.createDocument(
          'survey_resp',
          {
            survey: id,
            values,
          },
          survey.trace_owner && token ? {token} : {anonymous: true},
        );

        alert('成功送出問卷');
        setValues(getInitialSurveyValues(survey));
      } catch (ex) {
        console.warn('SurveyDetailPage ex', ex);
      }
      AppActions.setLoading(false);
    }
  }

  const disabled = showResult;

  if (!survey) {
    return (
      <Wrapper>
        <div className="content" />
      </Wrapper>
    );
  } else if (!survey.anonymous && !token) {
    return (
      <Wrapper>
        <div className="content">
          <h2>此為具名問卷, 請先登入</h2>
        </div>
      </Wrapper>
    );
  } else if (prevResp) {
    return (
      <Wrapper>
        <div className="content">
          <h2>您已填寫過此問卷</h2>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="content">
        <h2>{survey.name}</h2>

        {showResult && (
          <div className="result">
            <div className="total">共{resps.length}人作答</div>
            <div className="hint">
              <div>
                * 更詳細的資料請至
                <Button
                  href={`/admin/survey_resps`}
                  type="link"
                  target="_blank">
                  此處
                </Button>
              </div>
              <div>* 請於以上連結查詢關鍵字 {id}</div>
            </div>
          </div>
        )}

        <div className="questions">
          {survey.questions.map((q, idx) => {
            return (
              <div key={idx}>
                <div className="name">
                  <span className="idx">{idx + 1}.</span>
                  <span className="type">
                    {QuestionTypeLabel[q.type] || '---'}
                  </span>
                  {q.title}
                </div>

                {['single', 'multiple'].indexOf(q.type) > -1 && (
                  <div className="choices">
                    {(q.choices || []).map((c, ci) => {
                      const checked = isOptionChosen(idx, ci);
                      const onChange = (e) => {
                        setOptionChecked(idx, ci, e.target.checked, q.type);
                      };
                      const stats = {};
                      if (showResult) {
                        stats.count = getOptionSelectedCnt(resps, idx, ci);
                        stats.percentage = resps.length
                          ? ((stats.count * 100) / resps.length).toFixed(2)
                          : 0;
                      }
                      return (
                        <div key={ci}>
                          <div className="row">
                            <input
                              id={`${idx}-${ci}`}
                              type="checkbox"
                              checked={checked}
                              onChange={onChange}
                              disabled={disabled}
                            />
                            <label className="text" htmlFor={`${idx}-${ci}`}>
                              <span className="idx">{ci + 1}.</span>
                              {c.text}
                            </label>
                          </div>
                          {showResult && (
                            <div className="result">{`${stats.count} (${stats.percentage}%)`}</div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}

                {q.type === 'bool' && (
                  <div className="choices">
                    {['Y'].map((c, ci) => {
                      const checked = !!values[idx];
                      const onChange = (e) => {
                        const nextValues = [...values];
                        nextValues[idx] = e.target.checked;
                        setValues(nextValues);
                      };
                      const stats = {};
                      if (showResult) {
                        stats.count = getOptionSelectedCnt(resps, idx);
                        stats.percentage = resps.length
                          ? ((stats.count * 100) / resps.length).toFixed(2)
                          : 0;
                      }
                      return (
                        <div key={ci}>
                          <div className="row">
                            <input
                              id={`${idx}-${ci}`}
                              type="checkbox"
                              checked={checked}
                              onChange={onChange}
                              disabled={disabled}
                            />
                            <label className="text" htmlFor={`${idx}-${ci}`}>
                              是
                            </label>
                          </div>
                          {showResult && (
                            <div className="result">{`${stats.count} (${stats.percentage}%)`}</div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}

                {q.type === 'essay' && (
                  <>
                    <textarea
                      value={values[idx]}
                      onChange={(e) => {
                        const nextValues = [...values];
                        nextValues[idx] = e.target.value;
                        setValues(nextValues);
                      }}
                      disabled={disabled}
                    />
                    {showResult && (
                      <div className="result">{`此題型無統計數據`}</div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>

        {!showResult && (
          <div className="actions">
            <Button type="primary" onClick={submitMySurvey}>
              送出
            </Button>
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  padding-top: var(--topNavBarHeight);
  background-color: #eee;

  ${(props) => (props.hasNavBar ? 'padding-top: var(--topNavBarHeight);' : '')};

  & .row {
    display: flex;
    align-items: center;
  }

  & > .content {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: white;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.33);
    border-radius: 6px;

    & > .result {
      margin-bottom: 20px;
      color: blue;

      & > .total {
        font-size: 16px;
      }
    }

    & > .questions {
      & > * {
        margin-bottom: 20px;

        & > .name {
          font-size: 16px;
          margin-bottom: 10px;

          & .idx {
            font-weight: bold;
            margin-right: 6px;
          }

          & .type {
            padding: 4px;
            background-color: #ddd;
            border-radius: 4px;
            margin-right: 5px;
          }
        }

        & textarea {
          width: 100%;
        }

        & > .choices {
          font-size: 14px;
          min-height: 32px;

          & > * {
            margin-bottom: 6px;

            & input[type='checkbox'] {
              margin-right: 10px;
            }

            & .idx {
              font-weight: bold;
              margin-right: 3px;
            }
          }
        }

        & .result {
          color: blue;
          font-size: 16px;
        }
      }
    }

    & > .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

export default withPageEntry(SurveyDetailPage);
